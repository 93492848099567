import React, { useState, useEffect } from "react"
import authService from "../../services/authService"
import axios from "axios"
import "./Profile.css"
import { Button } from "@primer/react"

function Profile() {
  const [user, setUser] = useState(null)

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const currentUser = authService.getCurrentUser()
        const response = await axios.get(
          "https://dayx-24wwhw5ria-uw.a.run.app/api/accounts/user/",
          {
            headers: {
              Authorization: `Bearer ${currentUser.access}`,
            },
          }
        )
        setUser(response.data)
      } catch (error) {
        console.log(error)
      }
    }

    fetchUserProfile()
  }, [])

  const handleResendVerification = async (email) => {
    try {
      await authService.resendVerificationEmail(email)
      // alert("Verification email sent successfully!");
    } catch (error) {
      console.log(error)
      // alert("Failed to send verification email.");
    }
  }

  if (!user) {
    return <div>Loading...</div>
  }

  return (
    <div>
      <h2>Profile</h2>
      <p>Username: {user.username}</p>
      <p>
        Email: {user.email}{" "}
        <span
          style={{
            display: "inline-flex",
            alignItems: "center",
            color: user.email_verified ? "green" : "red",
          }}
        >
          {user.email_verified ? "Verified" : "Unverified"}{" "}
          {!user.email_verified && (
            <Button
              size="small"
              onClick={() => handleResendVerification(user.email)}
              style={{ marginLeft: "8px" }}
            >
              Resend Verification
            </Button>
          )}
        </span>
      </p>
    </div>
  )
}

export default Profile
