// src/Chat.js
import React, { useState } from "react"
import axios from "axios"
import authService from "../../services/authService"
import "./Chat.css"

function Chat() {
  const [messages, setMessages] = useState([])
  const [inputMessage, setInputMessage] = useState("")

  const handleSendMessage = async () => {
    if (inputMessage.trim() !== "") {
      const newMessage = {
        text: inputMessage,
        sender: "user",
      }
      setMessages([...messages, newMessage])
      setInputMessage("")

      try {
        const currentUser = authService.getCurrentUser()
        const response = await axios.post(
          "https://backend.infinitylab.ai/api/dayx/chat/",
          {
            user: inputMessage,
          },
          {
            headers: {
              Authorization: `Bearer ${currentUser.access}`,
            },
          }
        )

        const serverMessage = {
          text: response.data.assistant,
          sender: "server",
        }
        setMessages((prevMessages) => [...prevMessages, serverMessage])
      } catch (error) {
        console.log(error)
        const serverMessage = {
          text: "Sorry, I couldn't process your message. Please try again later.",
          sender: "server",
        }
        setMessages((prevMessages) => [...prevMessages, serverMessage])
      }
    }
  }

  return (
    <div className="chat-container">
      <h2>Chat</h2>
      <div className="chat-window">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`message ${
              message.sender === "user" ? "user-message" : "server-message"
            }`}
          >
            {message.text}
          </div>
        ))}
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder="Type your message..."
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  )
}

export default Chat
