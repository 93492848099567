import React, { useEffect, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import authService from "../../services/authService"
import { generateCodeVerifier, generateCodeChallenge } from "../../utils/oauth"

function Login() {
  const navigate = useNavigate()

  useEffect(() => {
    if (authService.getCurrentUser()) {
      navigate("/profile")
    }
  }, [navigate])

  const handleLogin = useCallback(async () => {
    const codeVerifier = generateCodeVerifier()
    localStorage.setItem("codeVerifier", codeVerifier)
    generateCodeChallenge(codeVerifier).then((codeChallenge) => {
      const loginUrl = `https://backend.infinitylab.ai/o/authorize/?client_id=w3BeUGNXcfre60sSsCLEvE4guRIBKm2GprA3lQ3Q&redirect_uri=https://dayx.me/infinity/login/callback/&scope=read&response_type=code&state=XKQ9RZhDcXibYDfq&code_challenge=${codeChallenge}&code_challenge_method=S256`
      window.location.href = loginUrl
    })
  }, [])

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6">Login with Infinity ID</h2>
        <div className="flex justify-center">
          <button
            onClick={handleLogin}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          >
            Login
          </button>
        </div>
      </div>
    </div>
  )
}

export default Login
