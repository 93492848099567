function generateCodeVerifier() {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  const length = Math.floor(Math.random() * (128 - 43 + 1)) + 43
  let codeVerifier = ""
  for (let i = 0; i < length; i++) {
    codeVerifier += characters.charAt(
      Math.floor(Math.random() * characters.length)
    )
  }
  return codeVerifier
}

function generateCodeChallenge(codeVerifier) {
  const encoder = new TextEncoder()
  const data = encoder.encode(codeVerifier)
  return crypto.subtle.digest("SHA-256", data).then((digest) => {
    const base64Digest = btoa(
      String.fromCharCode.apply(null, new Uint8Array(digest))
    )
    return base64Digest
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "")
  })
}

export { generateCodeVerifier, generateCodeChallenge }
