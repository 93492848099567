import React from "react"
import { Navigate } from "react-router-dom"
import authService from "../services/authService"

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = authService.getCurrentUser()
  return isAuthenticated ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/login" replace state={{ from: rest.location }} />
  )
}

export default PrivateRoute
