import axios from "axios"

const API_URL = "https://backend.infinitylab.ai/api/accounts"
const DAYX_API_URL = "https://dayx-24wwhw5ria-uw.a.run.app/api/accounts"

const signup = (email, password1, password2) => {
  return axios.post(`${API_URL}/register/`, { email, password1, password2 })
}

const login = (email, password) => {
  return axios
    .post(`${API_URL}/login/`, { email, password })
    .then((response) => {
      if (response.data.access) {
        localStorage.setItem("user", JSON.stringify(response.data))
      }
      return response.data
    })
}

const logout = () => {
  localStorage.removeItem("user")
}

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"))
}

const resetPassword = (email) => {
  return axios.post(`${API_URL}/password/reset/`, { email })
}

const resetPasswordConfirm = (uid, token, newPassword1, newPassword2) => {
  return axios.post(`${API_URL}/password/reset/confirm/`, {
    new_password1: newPassword1,
    new_password2: newPassword2,
    uid: uid,
    token: token,
  })
}

const verifyEmail = (token) => {
  return axios.post(`${API_URL}/register/verify-email/`, {
    key: token,
  })
}

const resendVerificationEmail = (email) => {
  return axios.post(`${API_URL}/register/resend-email/`, {
    email: email,
  })
}

const getAccessToken = async (code, codeVerifier) => {
  try {
    const response = await axios.post(`${DAYX_API_URL}/infinity/`, {
      code: code,
      code_verifier: codeVerifier,
    })
    const { access_token } = response.data
    localStorage.setItem("user", JSON.stringify(response.data))
    return access_token
  } catch (error) {
    throw error
  }
}
const authService = {
  signup,
  login,
  logout,
  getCurrentUser,
  resetPassword,
  resetPasswordConfirm,
  verifyEmail,
  resendVerificationEmail,
  getAccessToken,
}

export default authService
