import React, { useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import authService from "../../services/authService"

function PasswordConfirm() {
  const { uid, token } = useParams()
  const [newPassword1, setNewPassword1] = useState("")
  const [newPassword2, setNewPassword2] = useState("")
  const navigate = useNavigate()

  const handleResetPassword = async (e) => {
    e.preventDefault()

    if (newPassword1 !== newPassword2) {
      console.log("Passwords do not match")
      // Show an error message or perform any other actions
      return
    }

    try {
      await authService.resetPasswordConfirm(
        uid,
        token,
        newPassword1,
        newPassword2
      )

      console.log("Password reset successful")
      // Show a success message or navigate to another page
      navigate("/login")
    } catch (error) {
      console.log(error)
      // Handle the error or show an error message
    }
  }

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6">Reset Password</h2>
        <form onSubmit={handleResetPassword}>
          <div className="mb-4">
            <label
              htmlFor="newPassword1"
              className="block text-gray-700 font-bold mb-2"
            >
              New Password
            </label>
            <input
              type="password"
              id="newPassword1"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              value={newPassword1}
              onChange={(e) => setNewPassword1(e.target.value)}
              required
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="newPassword2"
              className="block text-gray-700 font-bold mb-2"
            >
              Confirm New Password
            </label>
            <input
              type="password"
              id="newPassword2"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              value={newPassword2}
              onChange={(e) => setNewPassword2(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600"
          >
            Reset Password
          </button>
        </form>
      </div>
    </div>
  )
}

export default PasswordConfirm
