import React from "react"
import { useParams, useNavigate } from "react-router-dom"
import authService from "../../services/authService"

function VerifyEmail() {
  const { token } = useParams()
  const navigate = useNavigate()

  const handleVerifyEmail = async () => {
    try {
      await authService.verifyEmail(token)
      console.log("Email verification successful")
      // Show a success message or navigate to another page
      navigate("/login")
    } catch (error) {
      console.log(error)
      // Handle the error or show an error message
    }
  }

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6">Verify Email</h2>
        <p className="mb-6">
          Please click the button below to verify your email address.
        </p>
        <button
          onClick={handleVerifyEmail}
          className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600"
        >
          Verify Email
        </button>
      </div>
    </div>
  )
}

export default VerifyEmail
