import React, { useEffect, useCallback } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import authService from "../../services/authService"

function OAuthCallback({ onLogin }) {
  const navigate = useNavigate()
  const location = useLocation()

  const handleOAuthCallback = useCallback(
    async (code) => {
      try {
        const codeVerifier = localStorage.getItem("codeVerifier")
        await authService.getAccessToken(code, codeVerifier)
        localStorage.removeItem("codeVerifier")
        onLogin()
        navigate("/profile")
      } catch (error) {
        console.log(error)
        localStorage.removeItem("codeVerifier")
        navigate("/login")
      }
    },
    [onLogin, navigate]
  )

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const code = searchParams.get("code")
    if (code) {
      handleOAuthCallback(code)
    } else {
      console.log("Authorization code not found")
      navigate("/login")
    }
  }, [location, navigate, handleOAuthCallback])

  return <div>Processing OAuth callback...</div>
}

export default OAuthCallback
