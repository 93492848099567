import { useState, React } from "react"
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"
import PrivateRoute from "./routes/PrivateRoute"
import Login from "./pages/Auth/Login"
import Signup from "./pages/Auth/Signup"
import OAuthLogin from "./pages/Auth/OAuthLogin"
import Profile from "./pages/Profile/Profile"
import PasswordConfirm from "./pages/Auth/PasswordConfirm"
import VerifyEmail from "./pages/Auth/VerifyEmail"
import Chat from "./pages/Chat/Chat"
import authService from "./services/authService"
import { ThemeProvider, BaseStyles } from "@primer/react"

// import AudioRecorderWrapper from "./components/AudioRecorder/AudioRecorderWrapper"

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    authService.getCurrentUser() !== null
  )

  const handleLogin = () => {
    setIsAuthenticated(true)
  }

  const handleLogout = () => {
    authService.logout()
    setIsAuthenticated(false)
  }

  return (
    <ThemeProvider>
      <BaseStyles>
        <Router>
          <div className="min-h-screen bg-gray-100">
            <nav className="bg-white shadow">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                        width="32"
                        height="32"
                      >
                        <rect
                          x="10"
                          y="10"
                          width="80"
                          height="80"
                          rx="15"
                          ry="15"
                          fill="#4F46E5"
                        />
                        <path
                          d="M 30 25 L 30 75 L 60 75 C 70 75 75 70 75 60 C 75 50 70 45 60 45 C 70 45 75 40 75 30 C 75 20 70 15 60 15 L 30 25 Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        <Link
                          to="/"
                          className="text-gray-500 hover:text-gray-700 px-3 py-2 rounded-md text-sm font-medium"
                        >
                          Home
                        </Link>
                        <Link
                          to="/profile"
                          className="text-gray-500 hover:text-gray-700 px-3 py-2 rounded-md text-sm font-medium"
                        >
                          Profile
                        </Link>
                        <Link
                          to="/chat"
                          className="text-gray-500 hover:text-gray-700 px-3 py-2 rounded-md text-sm font-medium"
                        >
                          Chat
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    {!isAuthenticated ? (
                      <div className="ml-4 flex items-center md:ml-6">
                        <Link
                          to="/login"
                          className="text-gray-500 hover:text-gray-700 px-3 py-2 rounded-md text-sm font-medium"
                        >
                          Login
                        </Link>
                        <Link
                          to="/signup"
                          className="ml-4 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                        >
                          Signup
                        </Link>
                      </div>
                    ) : (
                      <button onClick={handleLogout}>Logout</button>
                    )}
                  </div>
                </div>
              </div>
            </nav>
            <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
              <div className="px-4 py-6 sm:px-0">
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route
                    path="/profile"
                    element={<PrivateRoute component={Profile} />}
                  />
                  <Route
                    path="/chat"
                    element={<PrivateRoute component={Chat} />}
                  />
                  <Route
                    path="/password/reset/confirm/:uid/:token/"
                    element={<PasswordConfirm />}
                  />
                  <Route
                    path="/email/confirm/:token/"
                    element={<VerifyEmail />}
                  />
                  <Route path="/" element={<Home />} />
                  <Route
                    path="/infinity/login/callback/"
                    element={<OAuthLogin onLogin={handleLogin} />}
                  />
                </Routes>
              </div>
            </main>

            <footer className="bg-white shadow">
              <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <p className="text-center text-gray-500">
                  &copy; {new Date().getFullYear()} Dayx. All rights reserved.
                </p>
              </div>
            </footer>
          </div>
        </Router>
      </BaseStyles>
    </ThemeProvider>
  )
}

function Home() {
  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-4">Welcome to Your App</h2>
      <p className="text-gray-600">
        This is the home page of your application. You can customize this
        section to provide an overview or highlight key features of your app.
      </p>
    </div>
  )
}

export default App
